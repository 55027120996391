/*
    ----------
*/
/* 
    ----------
*/
/* 
    ---------- 
*/
/* 
    ----------
*/
.dE5574, body, html {
  font-family: "Nunito";
}

/* 
    ----------
*/
.S6pt64 {
  float: left;
}

.yJ452A {
  float: right;
}

.eTt257 {
  float: none;
}

/* 
    ----------
*/
.q7h2M9 {
  width: 25%;
}

.S09r6B {
  width: 50%;
}

.b7P443 {
  width: 75%;
}

.C96Q4r {
  width: 100%;
}

/* 
    ----------
*/
.eU7H63 {
  font-size: 10px;
}

.S7gw68 {
  font-size: 11px;
}

.iI0402 {
  font-size: 12px;
}

.xG0j09 {
  font-size: 13px;
}

.PlO170, .nF5A00 .o772w5 .u948a7, .nF5A00 .o772w5 .g4x76h {
  font-size: 14px;
}

.wI860z, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a, .nF5A00 .Z9c59A .Ve3477 a, .x1E6p6 .dI7075 a {
  font-size: 15px;
}

.Wm8G06, .eB9032 .Md5M31 .iL622B p, .eB9032 .AgS723 .Xm4170 span, .nF5A00 .Xo2C78 .qN430m p, .nF5A00 .y60J46 .Bk976Y .Fo342l p, body {
  font-size: 16px;
}

.bS8S40, .nF5A00 .Xo2C78 .qN430m a, .nF5A00 .Z9c59A .Ve3477 p {
  font-size: 17px;
}

.s4U1j1, .nF5A00 .y60J46 .dU9167 p {
  font-size: 18px;
}

.J589Ta, .nF5A00 .Ya5F02 .swiper-button-next::after, .nF5A00 .Ya5F02 .swiper-button-prev::after, .nF5A00 .Xo2C78 .p8O94M .swiper-button-next::after, .nF5A00 .Xo2C78 .p8O94M .swiper-button-prev::after {
  font-size: 19px;
}

.i260Up, .nF5A00 .o772w5 .x914i1 {
  font-size: 20px;
}

/* 
    ----------
*/
.C2y88Q {
  font-weight: lighter;
}

.u37E41 {
  font-weight: normal;
}

.Y4674t {
  font-weight: bold;
}

.zK3920 {
  font-weight: bolder;
}

/* 
    ----------
*/
.F2n16X {
  font-weight: 100;
}

.Y8k86e {
  font-weight: 200;
}

.y3Or88, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 h6, .nF5A00 .Z9c59A .Ve3477 h3, .nF5A00 .y60J46 .Bk976Y .Fo342l h4, .nF5A00 .y60J46 .Bk976Y .Fo342l h3, .nF5A00 .y60J46 .dU9167 h2, .x1E6p6 .dI7075 h1 {
  font-weight: 300;
}

.nS533w, .nF5A00 .Xo2C78 .qN430m p, .nF5A00 .Z9c59A .Ve3477 p, .nF5A00 .y60J46 .Bk976Y .Fo342l p, .nF5A00 .y60J46 .dU9167 p {
  font-weight: 400;
}

.K0cW96 {
  font-weight: 500;
}

.D933nr, .nF5A00 .o772w5 .g4x76h, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a, .nF5A00 .Xo2C78 .qN430m a, .nF5A00 .Z9c59A .Ve3477 a, .x1E6p6 .dI7075 a {
  font-weight: 600;
}

.jS8601, .nF5A00 .o772w5 .x914i1, .nF5A00 .Z9c59A .rE804F h6 {
  font-weight: 700;
}

.Zv29i2 {
  font-weight: 800;
}

.Is1259 {
  font-weight: 900;
}

/* 
    ----------
*/
.J284Xb, .x1E6p6 .dI7075 h1 {
  font-style: normal;
}

.Ep1U39 {
  font-style: oblique;
}

.R6t03r, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 h6, .nF5A00 .Z9c59A .Ve3477 h3, .nF5A00 .y60J46 .dU9167 h2 {
  font-style: italic;
}

/* 
    ----------
*/
.v6EU83, a {
  color: #00DEFF;
}

.M99v3q, .eB9032 .AgS723 .Xm4170 a svg, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 h6, .nF5A00 .Xo2C78 .qN430m a, .nF5A00 .Z9c59A .rE804F .yC670W svg, .nF5A00 .Z9c59A .rE804F .xE805J svg, .nF5A00 .Z9c59A .Ve3477 a, .nF5A00 .Z9c59A .Ve3477 p, .nF5A00 .Z9c59A .Ve3477 h3, .x1E6p6 .dI7075 a, .x1E6p6 .dI7075 h1 {
  color: #FFFFFF;
}

.vOx602, .nF5A00 .o772w5 .u948a7, .nF5A00 .o772w5 .g4x76h, .nF5A00 .o772w5 .x914i1, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a:hover, .nF5A00 .Z9c59A .Ve3477 a:hover, .x1E6p6 .dI7075 a:hover {
  color: #000000;
}

.Y0u334, .eB9032 .Md5M31 .iL622B p, .eB9032 .AgS723 .Xm4170 span {
  color: #D2D2D2;
}

.r4K8f4 {
  color: #292E31;
}

/* 
    ----------
*/
.cvC365 {
  fill: #00DEFF;
}

.f8T6I8 {
  fill: #FFFFFF;
}

.sRU313 {
  fill: #000000;
}

.Vr4196 {
  fill: #D2D2D2;
}

.b29YY3 {
  fill: #292E31;
}

/* 
    ----------
*/
.u0Bc59, .nF5A00 .Xo2C78 .qN430m a {
  background: #00DEFF;
}

.rP408N, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a:hover, .nF5A00 .Z9c59A .Ve3477 a:hover, .x1E6p6 .dI7075 a:hover, body {
  background: #FFFFFF;
}

.u3I753 {
  background: #000000;
}

.i3eE58 {
  background: #D2D2D2;
}

.gZ959C, .eB9032 .AgS723, .nF5A00 .Ya5F02, .x1E6p6 .gI85l3, .x1E6p6 .jM8583 {
  background: #292E31;
}

/* 
    ----------
*/
.wDC543, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before, .nF5A00 .Z9c59A::before, .x1E6p6 .gI85l3::before {
  display: inline-block;
}

.eF429z {
  display: inline-flex;
}

.xA723K {
  display: table-cell;
}

.X35k4K {
  display: table-row;
}

.bN2p30 {
  display: inline;
}

.S0u16d {
  display: flex;
}

.V81zg1 {
  display: block;
}

.oi8E43 {
  display: grid;
}

.zD661i {
  display: table;
}

.t3c0N7 {
  display: -webkit-box;
}

.hPP928 {
  display: none;
}

.s6D202 {
  display: list-item;
}

/* 
    ----------
*/
.fE0d48 {
  flex-direction: column;
}

.L640Ti {
  flex-direction: column-reverse;
}

.Y065Fd {
  flex-direction: row-reverse;
}

.fr598R {
  flex-direction: row;
}

/* 
    ----------
*/
.ZH339f {
  justify-content: flex-start;
}

.U080Kf {
  justify-content: flex-end;
}

.vR05U6 {
  justify-content: center;
}

.g1aS48 {
  justify-content: space-between;
}

.h18Ea4 {
  justify-content: space-around;
}

.aR51Y0 {
  justify-content: space-evenly;
}

/* 
    ----------
*/
.I4t79h {
  align-items: normal;
}

.nA9305 {
  align-items: stretch;
}

.d721Vx {
  align-items: center;
}

.Q3n44O {
  align-items: flex-start;
}

.J7l0G2 {
  align-items: flex-end;
}

.W44s9p {
  align-items: start;
}

.C6u87n {
  align-items: end;
}

.Q7Ae62 {
  align-items: baseline;
}

/* 
    ----------
*/
.xD280K {
  text-align: start;
}

.Q606yt {
  text-align: end;
}

.vC9r15 {
  text-align: center;
}

.yyO233 {
  text-align: justify;
}

/* 
    ----------
*/
.uUG847 {
  text-transform: lowercase;
}

.GzJ197 {
  text-transform: uppercase;
}

.cMq044 {
  text-transform: capitalize;
}

.e2M03y {
  text-transform: none;
}

/*
    ----------
*/
.vF5k47, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a, .nF5A00 .Z9c59A .Ve3477 a, .x1E6p6 .dI7075 a, a {
  text-decoration: none;
}

.F83vw7 {
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.a20K95 {
  -webkit-text-decoration: dotted;
          text-decoration: dotted;
}

.C71Dm7 {
  -webkit-text-decoration: double;
          text-decoration: double;
}

.El6174 {
  -webkit-text-decoration: solid;
          text-decoration: solid;
}

.r9zX81 {
  text-decoration: overline;
}

.sB248t {
  -webkit-text-decoration: wavy;
          text-decoration: wavy;
}

.Q3s2S1, a:hover {
  text-decoration: underline;
}

.h6T64Y {
  text-decoration: line-through;
}

/*
    ----------
*/
.cB3332 {
  text-underline-offset: 6px;
}

/*
   ----------
*/
.q8K60t {
  text-overflow: ellipsis;
}

.faJ087 {
  text-overflow: clip;
}

.m1BW70 {
  text-overflow: unset;
}

/*
    ----------
*/
.U596Kr {
  vertical-align: baseline;
}

.t0G834 {
  vertical-align: sub;
}

.ebZ371 {
  vertical-align: super;
}

.I7e03O {
  vertical-align: top;
}

.j844Eb {
  vertical-align: text-top;
}

.eQ738Y {
  vertical-align: middle;
}

.E0vm38 {
  vertical-align: bottom;
}

.z56Q50 {
  vertical-align: text-bottom;
}

/*
    ----------
*/
.gL0474 {
  white-space: normal;
}

.wqA166 {
  white-space: nowrap;
}

.Q7a1D3 {
  white-space: pre;
}

/* 
    ----------
*/
.cH8711, .x1E6p6 .gI85l3 {
  position: relative;
}

.gBt584, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before, .nF5A00 .Z9c59A::before, .x1E6p6 .gI85l3::before {
  position: absolute;
}

.p9V85g {
  position: sticky;
}

.uK7E77 {
  position: static;
}

.Ude336 {
  position: fixed;
}

/*
    ----------
*/
.x35Ac2 {
  overflow: visible;
}

.J057Si {
  overflow: hidden;
}

.jC732U {
  overflow: scroll;
}

.L9Rm29 {
  overflow: auto;
}

/*
    ----------
*/
.yL0811 {
  cursor: alias;
}

.v6CN61 {
  cursor: all-scroll;
}

.qE800M {
  cursor: auto;
}

.tJ204O {
  cursor: cell;
}

.sDN443 {
  cursor: col-resize;
}

.p8C19l {
  cursor: context-menu;
}

.E0m26B {
  cursor: copy;
}

.L567nt {
  cursor: crosshair;
}

.Ri032C {
  cursor: default;
}

.kD889h {
  cursor: e-resize;
}

.S6u0h5 {
  cursor: ew-resize;
}

.c2Q91N {
  cursor: grab;
}

.dZ9M00 {
  cursor: grabbing;
}

.gM266u {
  cursor: help;
}

.dP797B {
  cursor: move;
}

.j3Uh65 {
  cursor: n-resize;
}

.cZ180w {
  cursor: ne-resize;
}

.aH3174 {
  cursor: nw-resize;
}

.j53Z9D {
  cursor: nwse-resize;
}

.Gp2446 {
  cursor: no-drop;
}

.Lr9809 {
  cursor: not-allowed;
}

.N4g4m6 {
  cursor: pointer;
}

.k6G6h5 {
  cursor: progress;
}

.Yri682 {
  cursor: row-resize;
}

.Zd4o45 {
  cursor: s-resize;
}

.X2R6j8 {
  cursor: se-resize;
}

.p2M9c8 {
  cursor: sw-resize;
}

.Xw0F20 {
  cursor: text;
}

.Q6j5W4 {
  cursor: vertical-text;
}

.S344z0 {
  cursor: w-resize;
}

.z9V38t {
  cursor: wait;
}

.Ac102a {
  cursor: zoom-in;
}

.d6F48n {
  cursor: zoom-out;
}

.h30X35 {
  cursor: none;
}

/*
    ----------
*/
.Kg80G9 {
  word-wrap: normal;
}

.uQ136M {
  word-wrap: break-word;
}

/*
    ----------
*/
.z9X51G {
  pointer-events: none;
}

.Bj1642 {
  pointer-events: auto;
}

/*
    ----------
*/
.oQ637t {
  border-style: dotted;
}

.r380oV {
  border-style: dashed;
}

.F5m883 {
  border-style: solid;
}

.Fb292w {
  border-style: double;
}

.Fcx262 {
  border-style: groove;
}

.eS845b {
  border-style: ridge;
}

.L2sq24 {
  border-style: inset;
}

.t3KL23 {
  border-style: outset;
}

.Z7o516 {
  border-style: none;
}

.Wx078J {
  border-style: hidden;
}

/*
    ----------
*/
.ob2A34 {
  border: 0;
}

.xq62J3 {
  border-top: 0;
}

.PvN976 {
  border-bottom: 0;
}

.rJ26U8 {
  border-left: 0;
}

.LjJ561 {
  border-right: 0;
}

/*
    ----------
*/
.L72y8b {
  border-collapse: collapse;
}

.Z5y7o1 {
  border-collapse: separate;
}

/*
    ----------
*/
.m9X7C5 {
  margin: 0 auto;
}

/*
    ----------
*/
.pEO064 {
  margin: 0;
}

.vSV800 {
  margin-left: 0;
}

.D797aR {
  margin-right: 0;
}

.sX6873 {
  margin-top: 0;
}

.B2d81S {
  margin-bottom: 0;
}

/*
    ----------
*/
.a527eB {
  padding: 0;
}

.z8o5S4 {
  padding-top: 0;
}

.dW8M00 {
  padding-right: 0;
}

.C7D19o {
  padding-left: 0;
}

.R3a5i0 {
  padding-bottom: 0;
}

/*
    ----------
*/
.GO52g1 {
  border-radius: 0;
}

.g03C00 {
  border-radius: 25%;
}

.K79e1C {
  border-radius: 50%;
}

.pZR189 {
  border-radius: 75%;
}

.kZm977 {
  border-radius: 100%;
}

/*
    ----------
*/
.wzU404 {
  flex: 0 0 auto;
}

.uTM149 {
  flex: 1 1 auto;
}

.U977pl {
  flex: 0 1 auto;
}

.z097Vp {
  flex: 1 0 auto;
}

/*
    ----------
*/
.P1470a {
  flex-grow: 0;
}

.iw2U41 {
  flex-grow: 1;
}

.I750Xd {
  flex-grow: 2;
}

.S276Td {
  flex-grow: 3;
}

/*
    ----------
*/
.X594yq {
  flex-basis: 1;
}

.U716Tv {
  flex-basis: 2;
}

.Rzr931 {
  flex-basis: 3;
}

/*
    ----------
*/
.o9T8I8 {
  flex-wrap: wrap;
}

.R0X85t {
  flex-wrap: nowrap;
}

.Fv9Z90 {
  flex-wrap: wrap-reverse;
}

/*
    ----------
*/
.vE6956 {
  order: 1;
}

.XjX424 {
  order: 2;
}

.W85it5 {
  order: 3;
}

/*
    ----------
*/
.Q330w3 {
  align-content: stretch;
}

.v69MS6 {
  align-content: center;
}

.X19jp8 {
  align-content: space-evenly;
}

.v74JJ5 {
  align-content: flex-start;
}

.uH27J4 {
  align-content: flex-end;
}

.T3l2s4 {
  align-content: space-between;
}

.D123Qj {
  align-content: space-around;
}

/*
    ----------
*/
.j1Mr03 {
  flex-shrink: 0;
}

.n7OS97 {
  flex-shrink: 1;
}

/*
    ----------
*/
.Ax30Q1 {
  box-shadow: none;
}

/*
    ----------
*/
.J55M9e, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before, .nF5A00 .Z9c59A::before, .x1E6p6 .gI85l3::before {
  content: "";
}

/* 
    ----------
*/
.C4v153, .x1E6p6 .jM8583 button {
  outline: 0;
}

.sD881L {
  outline: auto;
}

/* 
    ----------
*/
.pL4U70 {
  opacity: 0;
}

.q693Lc {
  opacity: 25%;
}

.aT591i, .nF5A00 .Ya5F02 .swiper-button-next:hover, .nF5A00 .Ya5F02 .swiper-button-prev:hover, .nF5A00 .Xo2C78 .p8O94M .swiper-button-next:hover, .nF5A00 .Xo2C78 .p8O94M .swiper-button-prev:hover {
  opacity: 50%;
}

.S8k6H9 {
  opacity: 75%;
}

.aV498I {
  opacity: 100%;
}

/*
    ----------
*/
.cB943h {
  -webkit-line-clamp: 2;
}

.Bt6232 {
  -webkit-line-clamp: 3;
}

.X2a9R4 {
  -webkit-line-clamp: 4;
}

/*
    ----------
*/
.gDk539 {
  -webkit-box-orient: block-axis;
}

.J4i292 {
  -webkit-box-orient: inline-axis;
}

.uQ1c23 {
  -webkit-box-orient: horizontal;
}

.G7o96W {
  -webkit-box-orient: vertical;
}

/*
    ----------
*/
.c8R6W7 {
  list-style: decimal-leading-zero;
}

.WvX121 {
  list-style: lower-alpha;
}

.Iy85Z8 {
  list-style: lower-greek;
}

.X6y6H0 {
  list-style: lower-latin;
}

.xT170D {
  list-style: lower-roman;
}

.J564hZ {
  list-style: upper-alpha;
}

.Qd352p {
  list-style: upper-latin;
}

.Np616D {
  list-style: upper-roman;
}

.W18b0l {
  list-style: disc;
}

.bR099N {
  list-style: georgian;
}

.Jd2580 {
  list-style: inside;
}

.R723p4 {
  list-style: outside;
}

.dK9146 {
  list-style: square;
}

.h57Y8Q {
  list-style: circle;
}

.kO539q {
  list-style: armenian;
}

.vY001R {
  list-style: decimal;
}

.GnG350 {
  list-style: none;
}

/*
    ----------
*/
.C196Fv {
  writing-mode: horizontal-tb;
}

.Z95a8x {
  writing-mode: vertical-lr;
}

.x0F757 {
  writing-mode: vertical-rl;
}

/*
    ----------
*/
.Klc797, .eB9032 .AgS723 .Xm4170 a svg, .eB9032 .AgS723 .Xm4170 a, .nF5A00 .o772w5, .nF5A00 .Ya5F02 .swiper-button-next:hover::after, .nF5A00 .Ya5F02 .swiper-button-prev:hover::after, .nF5A00 .Ya5F02 .Ki05M5 .z3S713 a, .nF5A00 .Xo2C78 .p8O94M .swiper-button-next:hover::after, .nF5A00 .Xo2C78 .p8O94M .swiper-button-prev:hover::after, .nF5A00 .Z9c59A .Ve3477 a, .nF5A00 .y60J46 .Bk976Y .Fo342l, .nF5A00 .y60J46 .dU9167, .x1E6p6 .dI7075 a, .x1E6p6 .jM8583 button {
  transition: all 0.8s ease;
}

/*
    ----------
*/
.t0T2S2 {
  transform: none;
}

.wD3v95 {
  transform: rotate(90deg);
}

.Zka424 {
  transform: rotate(180deg);
}

.v1M46U {
  transform: rotate(270deg);
}

.j833Ma {
  transform: rotate(360deg);
}

/*
    ----------
*/
.jX0924 {
  -o-object-fit: fill;
     object-fit: fill;
}

.C0go67 {
  -o-object-fit: contain;
     object-fit: contain;
}

.Mg1457, .x1E6p6 .gI85l3 video {
  -o-object-fit: cover;
     object-fit: cover;
}

.uX61k8 {
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.y60E9p {
  -o-object-fit: none;
     object-fit: none;
}

/* 
    ----------
*/
.qW182T {
  line-height: 1;
}

.tSz942 {
  line-height: 1.5;
}

.YS535e {
  line-height: 2;
}

/*
    ----------
*/
.SFq502 {
  background-position: left top;
}

.iJM161 {
  background-position: left center;
}

.Gr070F {
  background-position: left bottom;
}

.Px402I {
  background-position: right top;
}

.t6K867 {
  background-position: right center;
}

.mG848G {
  background-position: right bottom;
}

.J2l421 {
  background-position: center top;
}

.F710rg, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N, .nF5A00 .Z9c59A::before, .nF5A00 .Z9c59A, .x1E6p6 .gI85l3::before {
  background-position: center center;
}

.vW8q13 {
  background-position: center bottom;
}

/*
    ----------
*/
.Tq143x {
  background-size: auto;
}

.T659Fi, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N, .nF5A00 .Z9c59A::before, .nF5A00 .Z9c59A, .x1E6p6 .gI85l3::before {
  background-size: cover;
}

.B4A44p {
  background-size: contain;
}

/*
    ----------
*/
.P6cE22 {
  background-repeat: repeat;
}

.XcJ938 {
  background-repeat: repeat-x;
}

.rYx754 {
  background-repeat: repeat-y;
}

.N965Go, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before, .nF5A00 .Ya5F02 .Ki05M5 .e4K33N, .nF5A00 .Z9c59A::before, .nF5A00 .Z9c59A, .x1E6p6 .gI85l3::before {
  background-repeat: no-repeat;
}

.rG790K {
  background-repeat: space;
}

.kV124B {
  background-repeat: round;
}

/*
    ----------
*/
.P779mc {
  box-sizing: border-box;
}

/*
    ----------
*/
.H9g95G {
  word-break: normal;
}

.kO189r {
  word-break: break-all;
}

.wK1970 {
  word-break: keep-all;
}

.kL64Z1 {
  word-break: break-word;
}

/*
    ----------
*/
.q629Gd {
  filter: brightness(0.25);
}

.jSO180 {
  filter: contrast(160%);
}

.H61jT8 {
  filter: saturate(3);
}

.wM7o82 {
  filter: invert(100%);
}

.Q8b7e3 {
  filter: grayscale(50%);
}

.T354Vs {
  filter: sepia(100%);
}

.U029ho {
  filter: blur(3px);
}

.o98b8U {
  filter: hue-rotate(180deg);
}

/*
    ----------
*/
.C23pq8 {
  resize: both;
}

.qS03n2 {
  resize: none;
}

.Ss37t2 {
  resize: horizontal;
}

.n3R12l {
  resize: vertical;
}

/*
    ----------
*/
/* 
    ----------
*/
/* 
    ----------
*/
:root {
  --css-a: #23272A;
  --css-b: #071C26DE;
  --css-c: #000000;
}

:root {
  --css-z: 32px;
  --css-y: 70px;
  --css-v: 40px;
  --css-s: 28px;
}

/* 
    ----------
*/
/* 
    ----------
*/
.x1E6p6 .jM8583 {
  width: 100%;
  height: 100px;
}
.x1E6p6 .jM8583 button {
  width: 100%;
  height: 100px;
  z-index: 11;
}
.x1E6p6 .jM8583 button img {
  width: 75px;
  height: auto;
}
.x1E6p6 .gI85l3 {
  width: 100%;
  height: 580px;
  margin-top: -100px !important;
}
.x1E6p6 .gI85l3 video {
  width: 100%;
  height: 100%;
}
.x1E6p6 .gI85l3::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 580px;
  background-color: var(--css-b);
  opacity: 0.6;
}
.x1E6p6 .dI7075 {
  left: 0;
  top: 0;
  width: 100%;
  height: 580px;
}
.x1E6p6 .dI7075 h1 {
  font-size: var(--css-v);
  line-height: 80px;
}
.x1E6p6 .dI7075 h1 strong {
  text-shadow: 0px 0px 25px rgba(148, 223, 255, 0.38);
}
.x1E6p6 .dI7075 a {
  width: 180px;
  height: 51px;
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 51px;
  line-height: 51px;
}
/* 
    ----------
*/
.nF5A00 .y60J46 .dU9167 h2 {
  color: var(--css-c);
  font-size: var(--css-z);
  line-height: 32px;
}
.nF5A00 .y60J46 .dU9167 h2::before {
  content: '"';
}
.nF5A00 .y60J46 .dU9167 h2::after {
  content: '"';
}
.nF5A00 .y60J46 .dU9167 p {
  color: var(--css-c);
}
.nF5A00 .y60J46 .Bk976Y .Fo342l h3 {
  font-size: var(--css-y);
  color: var(--css-c);
  letter-spacing: -2px;
  line-height: 76px;
}
.nF5A00 .y60J46 .Bk976Y .Fo342l h3::after {
  content: "+";
}
.nF5A00 .y60J46 .Bk976Y .Fo342l h4 {
  font-size: var(--css-y);
  color: var(--css-c);
  letter-spacing: -2px;
  line-height: 76px;
}
.nF5A00 .y60J46 .Bk976Y .Fo342l h4::after {
  content: "M+";
}
.nF5A00 .y60J46 .Bk976Y .Fo342l p {
  color: var(--css-c);
}
.nF5A00 .Z9c59A {
  width: 100%;
  height: 900px;
  background-image: url("./Media/Image/001.jpg");
}
.nF5A00 .Z9c59A::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--css-b);
  opacity: 0.6;
}
.nF5A00 .Z9c59A .Ve3477 {
  width: 100%;
  height: 900px;
}
.nF5A00 .Z9c59A .Ve3477 h3 {
  font-size: var(--css-v);
  line-height: 80px;
}
.nF5A00 .Z9c59A .Ve3477 p {
  max-width: 1100px;
}
.nF5A00 .Z9c59A .Ve3477 a {
  width: 254px;
  height: 51px;
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 51px;
  line-height: 51px;
}
.nF5A00 .Z9c59A .rE804F .xE805J {
  width: 100%;
  height: 110px;
}
.nF5A00 .Z9c59A .rE804F .xE805J svg {
  width: 34px;
  height: 34px;
}
.nF5A00 .Z9c59A .rE804F .yC670W {
  width: 100%;
  height: 200px;
}
.nF5A00 .Z9c59A .rE804F .yC670W svg {
  width: 34px;
  height: 34px;
}
.nF5A00 .Z9c59A .rE804F h6 {
  font-size: var(--css-s);
}
.nF5A00 .Xo2C78 .qN430m {
  width: 100%;
  height: 293px;
}
.nF5A00 .Xo2C78 .qN430m h4 {
  font-size: var(--css-z);
  color: var(--css-c);
}
.nF5A00 .Xo2C78 .qN430m p {
  color: var(--css-c);
}
.nF5A00 .Xo2C78 .qN430m a {
  width: 151px;
  height: 46px;
  border-radius: 46px;
  line-height: 46px;
}
.nF5A00 .Xo2C78 .p8O94M {
  width: 100%;
  height: auto;
}
.nF5A00 .Xo2C78 .p8O94M .swiper-button-prev {
  width: 60px;
  height: 60px;
  background-color: var(--css-c);
  opacity: 0.1;
}
.nF5A00 .Xo2C78 .p8O94M .swiper-button-prev::after {
  color: white;
}
.nF5A00 .Xo2C78 .p8O94M .swiper-button-prev:hover::after {
  color: #FFFFFF;
}
.nF5A00 .Xo2C78 .p8O94M .swiper-button-next {
  width: 60px;
  height: 60px;
  background-color: var(--css-c);
  opacity: 0.1;
}
.nF5A00 .Xo2C78 .p8O94M .swiper-button-next::after {
  color: white;
}
.nF5A00 .Xo2C78 .p8O94M .swiper-button-next:hover::after {
  color: #FFFFFF;
}
.nF5A00 .Ya5F02 {
  width: 100%;
  height: 692px;
}
.nF5A00 .Ya5F02 .Ki05M5 .e4K33N {
  width: 100%;
  height: 692px;
}
.nF5A00 .Ya5F02 .Ki05M5 .e4K33N::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 692px;
  background-color: var(--css-b);
}
.nF5A00 .Ya5F02 .Ki05M5 .z3S713 {
  width: 100%;
  height: 692px;
}
.nF5A00 .Ya5F02 .Ki05M5 .z3S713 h6 {
  font-size: var(--css-v);
  line-height: 80px;
}
.nF5A00 .Ya5F02 .Ki05M5 .z3S713 a {
  width: 254px;
  height: 51px;
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 51px;
  line-height: 51px;
}
.nF5A00 .Ya5F02 .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  background-color: rgba(255, 255, 255, 0.5);
}
.nF5A00 .Ya5F02 .swiper-pagination-bullet-active {
  background-color: #FFFFFF;
}
.nF5A00 .Ya5F02 .swiper-button-prev {
  width: 60px;
  height: 60px;
  background-color: var(--css-c);
  opacity: 0.1;
}
.nF5A00 .Ya5F02 .swiper-button-prev::after {
  color: white;
}
.nF5A00 .Ya5F02 .swiper-button-prev:hover::after {
  color: #FFFFFF;
}
.nF5A00 .Ya5F02 .swiper-button-next {
  width: 60px;
  height: 60px;
  background-color: var(--css-c);
  opacity: 0.1;
}
.nF5A00 .Ya5F02 .swiper-button-next::after {
  color: white;
}
.nF5A00 .Ya5F02 .swiper-button-next:hover::after {
  color: #FFFFFF;
}
.nF5A00 .o772w5 {
  width: 100%;
  height: 100vh;
}
.nF5A00 .o772w5 .g4x76h {
  line-height: 24px;
}
.nF5A00 .o772w5 .u948a7 {
  line-height: 24px;
}

/* 
    ----------
*/
.eB9032 .AgS723 {
  width: 100%;
  height: 450px;
}
.eB9032 .AgS723 .Xm4170 {
  width: 100%;
  height: 450px;
}
.eB9032 .AgS723 .Xm4170 a svg {
  width: 32px;
  height: 32px;
}
.eB9032 .AgS723 .Xm4170 span svg {
  width: 18px;
  height: 18px;
  margin: 0 10px 0 0;
}
.eB9032 .AgS723 .do6Q94 {
  width: 100%;
  height: 450px;
}
.eB9032 .AgS723 .do6Q94 iframe {
  width: 100%;
  height: 100%;
}
.eB9032 .Md5M31 {
  width: 100%;
  height: 85px;
  background: var(--css-a);
}
.eB9032 .Md5M31 .iL622B {
  width: 100%;
  height: 85px;
}
.eB9032 .Md5M31 .iL622B p {
  line-height: 85px;
}/*# sourceMappingURL=App.css.map */