@media (min-width: 1280px) and (max-width: 1281px){}
@media (min-width: 1024px) and (max-width: 1279px){}
@media screen and (max-width: 1024px){
    .gI85l3{ height: auto!important }
    .gI85l3{ margin: 0!important }
    .Md5M31, .iL622B{ height: 75px!important }
    .AgS723, .Xm4170{ height: auto!important }
    .AgS723, .Xm4170{ padding: 20px 0 0 0!important }
    .do6Q94{ padding: 20px 0!important }
    .x1E6p6 div:nth-child(2){ margin: 0!important }
    .gI85l3::before, .dI7075{ height: 100%!important }
}
@media screen and (max-width: 991.2px){
    .x1E6p6 .gI85l3{ position: absolute!important }
}
@media (min-width: 801px) and (max-width: 991.2px){
    .jM8583 button img{ width: 60px!important }
    .Xm4170 a svg{ width: 24px!important }
    .Xm4170 a svg{ height: 24px!important }
    .dI7075 h1{ font-size: 30px!important }
    .dI7075 h1{ line-height: 60px!important }
    .dI7075 a, .z3S713 a{ height: 44px!important }
    .dI7075 a, .z3S713 a{ line-height: 44px!important }
    .dI7075 a, .z3S713 a{ border-radius: 44px!important }
    .dI7075 a, .dU9167, .y60J46, .Fo342l, .qN430m, .p8O94M, .Z9c59A, .Ve3477 p, .xE805J, .yC670W, .z3S713 a, .Ya5F02{ margin: 20px 0 0 0!important }
    .dU9167 h2{ font-size: 24px!important }
    .dU9167 h2{ line-height: 30px!important }
    .dU9167 p{ margin: 8px 0 0 0!important }
    .Fo342l h3{ font-size: 30px!important }
    .Fo342l h4{ font-size: 30px!important }
    .Fo342l h4::after, .Fo342l h3::after{ font-size: 20px!important }
    .Fo342l h3, .Fo342l h4{ line-height: 35px!important }
    .Fo342l p{ margin: 2px 0 0 0!important }
    .Bk976Y{ margin: 0!important }
    .Bk976Y .row .col-md-4{ width: 33.3%!important }
    .Fo342l p{ font-size: 15px!important }
    .Fo342l h4::after, .Fo342l h3::after{ vertical-align: super }
    .Fo342l p{ text-transform: capitalize!important }
    .Fo342l h4::after, .Fo342l h3::after{ content: "+"!important }
    .qN430m{ height: auto!important }
    .qN430m h4{ margin: 4px 0 0 0!important }
    .qN430m h4, .Ve3477 h3, .z3S713 h6{ font-size: 24px!important }
    .z3S713 h6{ line-height: 24px!important }
    .Z9c59A, .Ve3477{ height: auto!important }
    .rE804F .row .col-md-4:nth-child(2){ display: none!important }
    .rE804F .row .col-md-4{ width: 50%!important }
    .Ve3477{ padding: 90px 0 74px 0!important }
    .Ve3477 h3{ line-height: 24px!important }
    .Ve3477 p{ max-width: auto!important }
    .xE805J, .yC670W, .Ya5F02, .e4K33N{ height: auto!important }
    .xE805J, .yC670W{ justify-content: space-between!important }
    .xE805J svg, .yC670W svg{ width: 28px!important }
    .xE805J svg, .yC670W svg{ height: 28px!important }
    .rE804F h6{ font-size: 22px!important }
    .z3S713{ height: 100%!important }
    .e4K33N{ padding: 150px 0!important }
    #Download::after{ content: "M+"!important }
}
@media (min-width: 720px) and (max-width: 800px){
    .jM8583 button img{ width: 50px!important }
    .Xm4170 a svg{ width: 24px!important }
    .Xm4170 a svg{ height: 24px!important }
    .dI7075 h1{ font-size: 30px!important }
    .dI7075 h1{ line-height: 60px!important }
    .dI7075 a, .z3S713 a{ height: 44px!important }
    .dI7075 a, .z3S713 a{ line-height: 44px!important }
    .dI7075 a, .z3S713 a{ border-radius: 44px!important }
    .dI7075 a, .dU9167, .y60J46, .Fo342l, .qN430m, .p8O94M, .Z9c59A, .Ve3477 p, .xE805J, .yC670W, .z3S713 a, .Ya5F02{ margin: 20px 0 0 0!important }
    .dU9167 h2{ font-size: 24px!important }
    .dU9167 h2{ line-height: 30px!important }
    .dU9167 p{ margin: 8px 0 0 0!important }
    .Fo342l h3{ font-size: 30px!important }
    .Fo342l h4{ font-size: 30px!important }
    .Fo342l h4::after, .Fo342l h3::after{ font-size: 20px!important }
    .Fo342l h3, .Fo342l h4{ line-height: 35px!important }
    .Fo342l p{ margin: 2px 0 0 0!important }
    .Bk976Y{ margin: 0!important }
    .Bk976Y .row .col-md-4{ width: 33.3%!important }
    .Fo342l p{ font-size: 15px!important }
    .Fo342l h4::after, .Fo342l h3::after{ vertical-align: super }
    .Fo342l p{ text-transform: capitalize!important }
    .Fo342l h4::after, .Fo342l h3::after{ content: "+"!important }
    .qN430m{ height: auto!important }
    .qN430m h4{ margin: 4px 0 0 0!important }
    .qN430m h4, .Ve3477 h3, .z3S713 h6{ font-size: 24px!important }
    .z3S713 h6{ line-height: 24px!important }
    .Z9c59A, .Ve3477{ height: auto!important }
    .rE804F .row .col-md-4:nth-child(2){ display: none!important }
    .rE804F .row .col-md-4{ width: 50%!important }
    .Ve3477{ padding: 90px 0 74px 0!important }
    .Ve3477 h3{ line-height: 24px!important }
    .Ve3477 p{ max-width: auto!important }
    .xE805J, .yC670W, .Ya5F02, .e4K33N{ height: auto!important }
    .xE805J, .yC670W{ justify-content: space-between!important }
    .xE805J svg, .yC670W svg{ width: 28px!important }
    .xE805J svg, .yC670W svg{ height: 28px!important }
    .rE804F h6{ font-size: 22px!important }
    .z3S713{ height: 100%!important }
    .e4K33N{ padding: 150px 0!important }
    #Download::after{ content: "M+"!important }
}
@media (min-width: 768px) and (max-width: 800px){
    .jM8583 button img{ width: 50px!important }
    .Xm4170 a svg{ width: 24px!important }
    .Xm4170 a svg{ height: 24px!important }
    .dI7075 h1{ font-size: 30px!important }
    .dI7075 h1{ line-height: 60px!important }
    .dI7075 a, .z3S713 a{ height: 44px!important }
    .dI7075 a, .z3S713 a{ line-height: 44px!important }
    .dI7075 a, .z3S713 a{ border-radius: 44px!important }
    .dI7075 a, .dU9167, .y60J46, .Fo342l, .qN430m, .p8O94M, .Z9c59A, .Ve3477 p, .xE805J, .yC670W, .z3S713 a, .Ya5F02{ margin: 20px 0 0 0!important }
    .dU9167 h2{ font-size: 24px!important }
    .dU9167 h2{ line-height: 30px!important }
    .dU9167 p{ margin: 8px 0 0 0!important }
    .Fo342l h3{ font-size: 30px!important }
    .Fo342l h4{ font-size: 30px!important }
    .Fo342l h4::after, .Fo342l h3::after{ font-size: 20px!important }
    .Fo342l h3, .Fo342l h4{ line-height: 35px!important }
    .Fo342l p{ margin: 2px 0 0 0!important }
    .Bk976Y{ margin: 0!important }
    .Bk976Y .row .col-md-4{ width: 33.3%!important }
    .Fo342l p{ font-size: 15px!important }
    .Fo342l h4::after, .Fo342l h3::after{ vertical-align: super }
    .Fo342l p{ text-transform: capitalize!important }
    .Fo342l h4::after, .Fo342l h3::after{ content: "+"!important }
    .qN430m{ height: auto!important }
    .qN430m h4{ margin: 4px 0 0 0!important }
    .qN430m h4, .Ve3477 h3, .z3S713 h6{ font-size: 24px!important }
    .z3S713 h6{ line-height: 24px!important }
    .Z9c59A, .Ve3477{ height: auto!important }
    .rE804F .row .col-md-4:nth-child(2){ display: none!important }
    .rE804F .row .col-md-4{ width: 50%!important }
    .Ve3477{ padding: 90px 0 74px 0!important }
    .Ve3477 h3{ line-height: 24px!important }
    .Ve3477 p{ max-width: auto!important }
    .xE805J, .yC670W, .Ya5F02, .e4K33N{ height: auto!important }
    .xE805J, .yC670W{ justify-content: space-between!important }
    .xE805J svg, .yC670W svg{ width: 28px!important }
    .xE805J svg, .yC670W svg{ height: 28px!important }
    .rE804F h6{ font-size: 22px!important }
    .z3S713{ height: 100%!important }
    .e4K33N{ padding: 150px 0!important }
    #Download::after{ content: "M+"!important }
}
@media (min-width:481px) and (max-width:767px){
    .jM8583 button img{ width: 50px!important }
    .Xm4170 a svg{ width: 24px!important }
    .Xm4170 a svg{ height: 24px!important }
    .dI7075 h1{ font-size: 23px!important }
    .dI7075 h1{ line-height: 25px!important }
    .dI7075 a, .z3S713 a{ height: 44px!important }
    .dI7075 a, .z3S713 a{ line-height: 44px!important }
    .dI7075 a, .z3S713 a{ border-radius: 44px!important }
    .dI7075 a, .dU9167, .y60J46, .Fo342l, .qN430m, .p8O94M, .Z9c59A, .Ve3477 p, .xE805J, .yC670W, .z3S713 a, .Ya5F02{ margin: 20px 0 0 0!important }
    .dU9167 h2{ font-size: 22px!important }
    .dU9167 h2{ line-height: 25px!important }
    .dU9167 p{ margin: 8px 0 0 0!important }
    .Fo342l h3{ font-size: 30px!important }
    .Fo342l h4{ font-size: 30px!important }
    .Fo342l h4::after, .Fo342l h3::after{ font-size: 20px!important }
    .Fo342l h3, .Fo342l h4{ line-height: 35px!important }
    .Fo342l p{ margin: 2px 0 0 0!important }
    .Bk976Y{ margin: 0!important }
    .Bk976Y .row .col-md-4{ width: 33.3%!important }
    .Fo342l p{ font-size: 15px!important }
    .Fo342l h4::after, .Fo342l h3::after{ vertical-align: super }
    .Fo342l p{ text-transform: capitalize!important }
    .Fo342l h4::after, .Fo342l h3::after{ content: "+"!important }
    .qN430m{ height: auto!important }
    .qN430m h4{ margin: 4px 0 0 0!important }
    .qN430m h4, .Ve3477 h3, .z3S713 h6{ font-size: 24px!important }
    .z3S713 h6{ line-height: 24px!important }
    .Z9c59A, .Ve3477{ height: auto!important }
    .rE804F .row .col-md-4:nth-child(2){ display: none!important }
    .Ve3477{ padding: 90px 0 74px 0!important }
    .Ve3477 h3{ line-height: 24px!important }
    .Ve3477 p{ max-width: auto!important }
    .xE805J, .yC670W, .Ya5F02, .e4K33N{ height: auto!important }
    .xE805J, .yC670W{ justify-content: space-between!important }
    .xE805J svg, .yC670W svg{ width: 28px!important }
    .xE805J svg, .yC670W svg{ height: 28px!important }
    .rE804F h6{ font-size: 22px!important }
    .z3S713{ height: 100%!important }
    .e4K33N{ padding: 150px 0!important }
    #Download::after{ content: "M+"!important }
}
@media (min-width:320px) and (max-width:480px){
    .jM8583 button img{ width: 50px!important }
    .Xm4170 a svg{ width: 24px!important }
    .Xm4170 a svg{ height: 24px!important }
    .dI7075 h1{ font-size: 18px!important }
    .dI7075 h1{ line-height: 25px!important }
    .dI7075 a, .z3S713 a{ height: 44px!important }
    .dI7075 a, .z3S713 a{ line-height: 44px!important }
    .dI7075 a, .z3S713 a{ border-radius: 44px!important }
    .dI7075 a, .dU9167, .y60J46, .Fo342l, .qN430m, .p8O94M, .Z9c59A, .Ve3477 p, .xE805J, .yC670W, .z3S713 a, .Ya5F02{ margin: 20px 0 0 0!important }
    .dU9167 h2{ font-size: 20px!important }
    .dU9167 h2{ line-height: 25px!important }
    .dU9167 p{ margin: 8px 0 0 0!important }
    .Fo342l h3{ font-size: 30px!important }
    .Fo342l h4{ font-size: 30px!important }
    .Fo342l h4::after, .Fo342l h3::after{ font-size: 20px!important }
    .Fo342l h3, .Fo342l h4{ line-height: 35px!important }
    .Fo342l p{ margin: 2px 0 0 0!important }
    .Bk976Y{ margin: 0!important }
    .Bk976Y .row .col-md-4{ width: 33.3%!important }
    .Fo342l p{ font-size: 15px!important }
    .Fo342l h4::after, .Fo342l h3::after{ vertical-align: super }
    .Fo342l p{ text-transform: capitalize!important }
    .Fo342l h4::after, .Fo342l h3::after{ content: "+"!important }
    .qN430m{ height: auto!important }
    .qN430m h4{ margin: 4px 0 0 0!important }
    .qN430m h4, .Ve3477 h3, .z3S713 h6{ font-size: 24px!important }
    .z3S713 h6{ line-height: 24px!important }
    .Z9c59A, .Ve3477{ height: auto!important }
    .rE804F .row .col-md-4:nth-child(2){ display: none!important }
    .Ve3477{ padding: 90px 0 74px 0!important }
    .Ve3477 h3{ line-height: 24px!important }
    .Ve3477 p{ max-width: auto!important }
    .xE805J, .yC670W, .Ya5F02, .e4K33N{ height: auto!important }
    .xE805J, .yC670W{ justify-content: space-between!important }
    .xE805J svg, .yC670W svg{ width: 28px!important }
    .xE805J svg, .yC670W svg{ height: 28px!important }
    .rE804F h6{ font-size: 22px!important }
    .z3S713{ height: 100%!important }
    .e4K33N{ padding: 150px 0!important }
    #Download::after{ content: "M+"!important }
}
@media (min-width:240px) and (max-width:319px){
    .jM8583 button img{ width: 50px!important }
    .Xm4170 a svg{ width: 24px!important }
    .Xm4170 a svg{ height: 24px!important }
    .dI7075 h1{ font-size: 18px!important }
    .dI7075 h1{ line-height: 25px!important }
    .dI7075 a, .z3S713 a{ height: 44px!important }
    .dI7075 a, .z3S713 a{ line-height: 44px!important }
    .dI7075 a, .z3S713 a{ border-radius: 44px!important }
    .dI7075 a, .dU9167, .y60J46, .Fo342l, .qN430m, .p8O94M, .Z9c59A, .Ve3477 p, .xE805J, .yC670W, .z3S713 a, .Ya5F02{ margin: 20px 0 0 0!important }
    .dU9167 h2{ font-size: 20px!important }
    .dU9167 h2{ line-height: 25px!important }
    .dU9167 p{ margin: 8px 0 0 0!important }
    .Fo342l h3{ font-size: 30px!important }
    .Fo342l h4{ font-size: 30px!important }
    .Fo342l h4::after, .Fo342l h3::after{ font-size: 20px!important }
    .Fo342l h3, .Fo342l h4{ line-height: 35px!important }
    .Fo342l p{ margin: 2px 0 0 0!important }
    .Bk976Y{ margin: 0!important }
    .Bk976Y .row .col-md-4{ width: 33.3%!important }
    .Fo342l p{ font-size: 15px!important }
    .Fo342l h4::after, .Fo342l h3::after{ vertical-align: super }
    .Fo342l p{ text-transform: capitalize!important }
    .Fo342l h4::after, .Fo342l h3::after{ content: "+"!important }
    .qN430m{ height: auto!important }
    .qN430m h4{ margin: 4px 0 0 0!important }
    .qN430m h4, .Ve3477 h3, .z3S713 h6{ font-size: 24px!important }
    .z3S713 h6{ line-height: 24px!important }
    .Z9c59A, .Ve3477{ height: auto!important }
    .rE804F .row .col-md-4:nth-child(2){ display: none!important }
    .Ve3477{ padding: 90px 0 74px 0!important }
    .Ve3477 h3{ line-height: 24px!important }
    .Ve3477 p{ max-width: auto!important }
    .xE805J, .yC670W, .Ya5F02, .e4K33N{ height: auto!important }
    .xE805J, .yC670W{ justify-content: space-between!important }
    .xE805J svg, .yC670W svg{ width: 28px!important }
    .xE805J svg, .yC670W svg{ height: 28px!important }
    .rE804F h6{ font-size: 22px!important }
    .z3S713{ height: 100%!important }
    .e4K33N{ padding: 150px 0!important }
    #Download::after{ content: "M+"!important }
}